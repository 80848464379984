<template>
  <HeaderItem />
  <!-- Carousel Start -->
  <div class="container-fluid p-0 mb-5 wow fadeIn" data-wow-delay="0.1s">
    <div id="header-carousel" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img
            class="w-100"
            src="img/001_boerderij-image-1442279.jpeg"
            alt="Image"
          />
          <div class="carousel-caption">
            <div class="container">
              <div class="row justify-content-start">
                <div class="col-lg-7">
                  <h1 class="display-2 mb-5 animated slideInDown">
                    Whole Broiler Chicken
                  </h1>
                  <a
                    href=""
                    class="btn btn-primary rounded-pill py-sm-3 px-sm-5"
                    >Products</a
                  >
                  <a
                    href=""
                    class="btn btn-secondary rounded-pill py-sm-3 px-sm-5 ms-3"
                    >Services</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <img class="w-100" src="img/eggs_.jpeg" alt="Image" />
          <div class="carousel-caption">
            <div class="container">
              <div class="row justify-content-start">
                <div class="col-lg-7">
                  <h1 class="display-2 mb-5 animated slideInDown">
                    Natural Eggs Is Always Healthy
                  </h1>
                  <a
                    href=""
                    class="btn btn-primary rounded-pill py-sm-3 px-sm-5"
                    >Products</a
                  >
                  <a
                    href=""
                    class="btn btn-secondary rounded-pill py-sm-3 px-sm-5 ms-3"
                    >Services</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#header-carousel"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#header-carousel"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
  <!-- Carousel End -->

  <!-- About Start -->
  <div class="container-xxl py-5">
    <div class="container">
      <div class="row g-5 align-items-center">
        <div class="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
          <div class="about-img position-relative overflow-hidden p-5 pe-0">
            <img class="img-fluid w-100" src="img/eggs_.jpeg" />
          </div>
        </div>
        <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
          <h1 class="display-5 mb-4">
            About Lil's Chicks farm & poultry farming
          </h1>
          <p class="mb-4">
            Our Story Established in 2018 by the visionary Akayesu Liliane,
            Lili's Chicks is more than a poultry farm; it's a commitment to
            quality, sustainability, and community. Liliane's passion for
            ethical farming and dedication to providing Rwanda with the best in
            poultry products drive everything we do.
          </p>
          <p>
            At Lili's Chicks, we believe in quality, sustainability, and the joy
            of bringing fresh, farm-produced chicken eggs and meat to your
            table. Explore our site to discover the story behind our farm, meet
            our founder Akayesu Liliane, and learn about the exceptional
            products we offer.
          </p>
          <p>
            <i class="fa fa-check text-primary me-3"></i>Tempor erat elitr rebum
            at clita
          </p>
          <p>
            <i class="fa fa-check text-primary me-3"></i>Aliqu diam amet diam et
            eos
          </p>
          <p>
            <i class="fa fa-check text-primary me-3"></i>Clita duo justo magna
            dolore erat amet
          </p>
          <a class="btn btn-primary rounded-pill py-3 px-5 mt-3" href=""
            >Read More</a
          >
        </div>
      </div>
    </div>
  </div>
  <!-- About End -->

  <!-- Feature Start -->
  <div class="container-fluid bg-light bg-icon my-5 py-6">
    <div class="container">
      <div
        class="section-header text-center mx-auto mb-5 wow fadeInUp"
        data-wow-delay="0.1s"
        style="max-width: 500px"
      >
        <h1 class="display-5 mb-3">Our Services</h1>
        <p>
          Tempor ut dolore lorem kasd vero ipsum sit eirmod sit. Ipsum diam
          justo sed rebum vero dolor duo.
        </p>
      </div>
      <div class="row g-4">
        <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
          <div class="bg-white text-center h-100 p-4 p-xl-5">
            <img class="img-fluid mb-4" src="img/Whole-Broiler-Chicken-1.jpeg" alt="" />
            <h4 class="mb-3">Chicken Meat</h4>
            <p class="mb-4">
              Indulge in the finest cuts of our locally sourced and carefully processed chicken meat.
            </p>
            <a
              class="btn btn-outline-primary border-2 py-2 px-4 rounded-pill"
              href=""
              >Read More</a
            >
          </div>
        </div>
        <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
          <div class="bg-white text-center h-100 p-4 p-xl-5">
            <img class="img-fluid mb-4" src="img/eggs_.jpeg" alt="" />
            <h4 class="mb-3">Chicken Eggs</h4>
            <p class="mb-4">
              Experience the freshness of our eggs, laid by happy and healthy hens on our Rwandan farm.
            </p>
            <a
              class="btn btn-outline-primary border-2 py-2 px-4 rounded-pill"
              href=""
              >Read More</a
            >
          </div>
        </div>
        <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
          <div class="bg-white text-center h-100 p-4 p-xl-5">
            <img class="img-fluid mb-4" src="img/eggs-and-chicken-1.jpeg" alt="" />
            <h4 class="mb-3">Biologically Safe</h4>
            <p class="mb-4">
              Tempor ut dolore lorem kasd vero ipsum sit eirmod sit. Ipsum diam
              justo sed vero dolor duo.
            </p>
            <a
              class="btn btn-outline-primary border-2 py-2 px-4 rounded-pill"
              href=""
              >Read More</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Feature End -->

  <!-- Product Start -->
  <div class="container-xxl py-5">
    <div class="container">
      <div class="row g-0 gx-5 align-items-end">
        <div class="col-lg-6">
          <div
            class="section-header text-start mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style="max-width: 500px"
          >
            <h1 class="display-5 mb-3">Our Products</h1>
            <p>
              We Produce Quality product of Chicken Meat & Chicken Eggs
            </p>
          </div>
        </div>
        <div
          class="col-lg-6 text-start text-lg-end wow slideInRight"
          data-wow-delay="0.1s"
        >
          <ul class="nav nav-pills d-inline-flex justify-content-end mb-5">
            <li class="nav-item me-2">
              <a
                class="btn btn-outline-primary border-2 active"
                data-bs-toggle="pill"
                href="#tab-1"
                >All</a
              >
            </li>
            <li class="nav-item me-2">
              <a
                class="btn btn-outline-primary border-2"
                data-bs-toggle="pill"
                href="#tab-2"
                >Chicken Meat
              </a>
            </li>
            <li class="nav-item me-0">
              <a
                class="btn btn-outline-primary border-2"
                data-bs-toggle="pill"
                href="#tab-3"
                >Chicken Eggs</a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="tab-content">
        <div id="tab-1" class="tab-pane fade show p-0 active">
          <div class="row g-4">
            <div
              class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div class="product-item">
                <div class="position-relative bg-light overflow-hidden">
                  <img class="img-fluid w-100" src="img/Whole-Broiler-Chicken-1.jpeg" alt="" />
                  <div
                    class="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3"
                  >
                    New
                  </div>
                </div>
                <div class="text-center p-4">
                  <a class="d-block h5 mb-2" href="">Chicken Meat</a>
                  <span class="text-primary me-1">RWF 10,000</span>
                  <span class="text-body text-decoration-line-through"
                    >RWF 12,000</span
                  >
                </div>
                <div class="d-flex border-top">
                  <small class="w-50 text-center border-end py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-eye text-primary me-2"></i>View detail</a
                    >
                  </small>
                  <small class="w-50 text-center py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-shopping-bag text-primary me-2"></i>Add
                      to cart</a
                    >
                  </small>
                </div>
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div class="product-item">
                <div class="position-relative bg-light overflow-hidden">
                  <img class="img-fluid w-100" src="img/eggs_.jpeg" alt="" />
                  <div
                    class="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3"
                  >
                    New
                  </div>
                </div>
                <div class="text-center p-4">
                  <a class="d-block h5 mb-2" href="">Chicken Eggs</a>
                  <span class="text-primary me-1">RWF 1000</span>
                  <span class="text-body text-decoration-line-through"
                    >RWF 1500</span
                  >
                </div>
                <div class="d-flex border-top">
                  <small class="w-50 text-center border-end py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-eye text-primary me-2"></i>View detail</a
                    >
                  </small>
                  <small class="w-50 text-center py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-shopping-bag text-primary me-2"></i>Add
                      to cart</a
                    >
                  </small>
                </div>
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div class="product-item">
                <div class="position-relative bg-light overflow-hidden">
                  <img class="img-fluid w-100" src="img/Raising-Meat-Chickens-beth-HOA-FeaturedFacebook-1.jpeg" alt="" />
                  <div
                    class="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3"
                  >
                    New
                  </div>
                </div>
                <div class="text-center p-4">
                  <a class="d-block h5 mb-2" href="">Raising Meat Chicken</a>
                  <span class="text-primary me-1">RWF 5000</span>
                  <span class="text-body text-decoration-line-through"
                    >RWF 7000</span
                  >
                </div>
                <div class="d-flex border-top">
                  <small class="w-50 text-center border-end py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-eye text-primary me-2"></i>View detail</a
                    >
                  </small>
                  <small class="w-50 text-center py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-shopping-bag text-primary me-2"></i>Add
                      to cart</a
                    >
                  </small>
                </div>
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div class="product-item">
                <div class="position-relative bg-light overflow-hidden">
                  <img class="img-fluid w-100" src="img/poultryeggs.jpeg" alt="" />
                  <div
                    class="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3"
                  >
                    New
                  </div>
                </div>
                <div class="text-center p-4">
                  <a class="d-block h5 mb-2" href="">Poultry Eggs</a>
                  <span class="text-primary me-1">RWF 200</span>
                  <span class="text-body text-decoration-line-through"
                    >RWF 300</span
                  >
                </div>
                <div class="d-flex border-top">
                  <small class="w-50 text-center border-end py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-eye text-primary me-2"></i>View detail</a
                    >
                  </small>
                  <small class="w-50 text-center py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-shopping-bag text-primary me-2"></i>Add
                      to cart</a
                    >
                  </small>
                </div>
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div class="product-item">
                <div class="position-relative bg-light overflow-hidden">
                  <img class="img-fluid w-100" src="img/eggs.webp" alt="" />
                  <div
                    class="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3"
                  >
                    New
                  </div>
                </div>
                <div class="text-center p-4">
                  <a class="d-block h5 mb-2" href="">Fresh Eggs</a>
                  <!-- <span class="text-primary me-1">$19.00</span>
                  <span class="text-body text-decoration-line-through"
                    >$29.00</span
                  > -->
                </div>
                <div class="d-flex border-top">
                  <small class="w-50 text-center border-end py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-eye text-primary me-2"></i>View detail</a
                    >
                  </small>
                  <small class="w-50 text-center py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-shopping-bag text-primary me-2"></i>Add
                      to cart</a
                    >
                  </small>
                </div>
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div class="product-item">
                <div class="position-relative bg-light overflow-hidden">
                  <img class="img-fluid w-100" src="img/eggs-and-chicken-1.jpeg" alt="" />
                  <div
                    class="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3"
                  >
                    New
                  </div>
                </div>
                <div class="text-center p-4">
                  <a class="d-block h5 mb-2" href="">Eggs & Chicken</a>
                  <!-- <span class="text-primary me-1">$19.00</span>
                  <span class="text-body text-decoration-line-through"
                    >$29.00</span
                  > -->
                </div>
                <div class="d-flex border-top">
                  <small class="w-50 text-center border-end py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-eye text-primary me-2"></i>View detail</a
                    >
                  </small>
                  <small class="w-50 text-center py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-shopping-bag text-primary me-2"></i>Add
                      to cart</a
                    >
                  </small>
                </div>
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div class="product-item">
                <div class="position-relative bg-light overflow-hidden">
                  <img class="img-fluid w-100" src="img/meat-chicken-breeds.jpeg" alt="" />
                  <div
                    class="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3"
                  >
                    New
                  </div>
                </div>
                <div class="text-center p-4">
                  <a class="d-block h5 mb-2" href="">Meat Chicken Breeds</a>
                  <!-- <span class="text-primary me-1">$19.00</span>
                  <span class="text-body text-decoration-line-through"
                    >$29.00</span
                  > -->
                </div>
                <div class="d-flex border-top">
                  <small class="w-50 text-center border-end py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-eye text-primary me-2"></i>View detail</a
                    >
                  </small>
                  <small class="w-50 text-center py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-shopping-bag text-primary me-2"></i>Add
                      to cart</a
                    >
                  </small>
                </div>
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div class="product-item">
                <div class="position-relative bg-light overflow-hidden">
                  <img class="img-fluid w-100" src="img/001_boerderij-image-1442279.jpeg" alt="" />
                  <div
                    class="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3"
                  >
                    New
                  </div>
                </div>
                <div class="text-center p-4">
                  <a class="d-block h5 mb-2" href="">Fresh Eggs and Meat</a>
                  <!-- <span class="text-primary me-1">$19.00</span>
                  <span class="text-body text-decoration-line-through"
                    >$29.00</span
                  > -->
                </div>
                <div class="d-flex border-top">
                  <small class="w-50 text-center border-end py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-eye text-primary me-2"></i>View detail</a
                    >
                  </small>
                  <small class="w-50 text-center py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-shopping-bag text-primary me-2"></i>Add
                      to cart</a
                    >
                  </small>
                </div>
              </div>
            </div>
            <div class="col-12 text-center wow fadeInUp" data-wow-delay="0.1s">
              <a class="btn btn-primary rounded-pill py-3 px-5" href=""
                >Browse More Products</a
              >
            </div>
          </div>
        </div>
        <div id="tab-2" class="tab-pane fade show p-0">
         <div class="row g-4">
            <div
              class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div class="product-item">
                <div class="position-relative bg-light overflow-hidden">
                  <img class="img-fluid w-100" src="img/Whole-Broiler-Chicken-1.jpeg" alt="" />
                  <div
                    class="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3"
                  >
                    New
                  </div>
                </div>
                <div class="text-center p-4">
                  <a class="d-block h5 mb-2" href="">Chicken Meat</a>
                  <span class="text-primary me-1">RWF 10,000</span>
                  <span class="text-body text-decoration-line-through"
                    >RWF 12,000</span
                  >
                </div>
                <div class="d-flex border-top">
                  <small class="w-50 text-center border-end py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-eye text-primary me-2"></i>View detail</a
                    >
                  </small>
                  <small class="w-50 text-center py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-shopping-bag text-primary me-2"></i>Add
                      to cart</a
                    >
                  </small>
                </div>
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div class="product-item">
                <div class="position-relative bg-light overflow-hidden">
                  <img class="img-fluid w-100" src="img/eggs_.jpeg" alt="" />
                  <div
                    class="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3"
                  >
                    New
                  </div>
                </div>
                <div class="text-center p-4">
                  <a class="d-block h5 mb-2" href="">Chicken Eggs</a>
                  <span class="text-primary me-1">RWF 1000</span>
                  <span class="text-body text-decoration-line-through"
                    >RWF 1500</span
                  >
                </div>
                <div class="d-flex border-top">
                  <small class="w-50 text-center border-end py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-eye text-primary me-2"></i>View detail</a
                    >
                  </small>
                  <small class="w-50 text-center py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-shopping-bag text-primary me-2"></i>Add
                      to cart</a
                    >
                  </small>
                </div>
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div class="product-item">
                <div class="position-relative bg-light overflow-hidden">
                  <img class="img-fluid w-100" src="img/Raising-Meat-Chickens-beth-HOA-FeaturedFacebook-1.jpeg" alt="" />
                  <div
                    class="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3"
                  >
                    New
                  </div>
                </div>
                <div class="text-center p-4">
                  <a class="d-block h5 mb-2" href="">Raising Meat Chicken</a>
                  <span class="text-primary me-1">RWF 5000</span>
                  <span class="text-body text-decoration-line-through"
                    >RWF 7000</span
                  >
                </div>
                <div class="d-flex border-top">
                  <small class="w-50 text-center border-end py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-eye text-primary me-2"></i>View detail</a
                    >
                  </small>
                  <small class="w-50 text-center py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-shopping-bag text-primary me-2"></i>Add
                      to cart</a
                    >
                  </small>
                </div>
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div class="product-item">
                <div class="position-relative bg-light overflow-hidden">
                  <img class="img-fluid w-100" src="img/poultryeggs.jpeg" alt="" />
                  <div
                    class="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3"
                  >
                    New
                  </div>
                </div>
                <div class="text-center p-4">
                  <a class="d-block h5 mb-2" href="">Poultry Eggs</a>
                  <span class="text-primary me-1">RWF 200</span>
                  <span class="text-body text-decoration-line-through"
                    >RWF 300</span
                  >
                </div>
                <div class="d-flex border-top">
                  <small class="w-50 text-center border-end py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-eye text-primary me-2"></i>View detail</a
                    >
                  </small>
                  <small class="w-50 text-center py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-shopping-bag text-primary me-2"></i>Add
                      to cart</a
                    >
                  </small>
                </div>
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div class="product-item">
                <div class="position-relative bg-light overflow-hidden">
                  <img class="img-fluid w-100" src="img/eggs.webp" alt="" />
                  <div
                    class="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3"
                  >
                    New
                  </div>
                </div>
                <div class="text-center p-4">
                  <a class="d-block h5 mb-2" href="">Fresh Eggs</a>
                  <!-- <span class="text-primary me-1">$19.00</span>
                  <span class="text-body text-decoration-line-through"
                    >$29.00</span
                  > -->
                </div>
                <div class="d-flex border-top">
                  <small class="w-50 text-center border-end py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-eye text-primary me-2"></i>View detail</a
                    >
                  </small>
                  <small class="w-50 text-center py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-shopping-bag text-primary me-2"></i>Add
                      to cart</a
                    >
                  </small>
                </div>
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div class="product-item">
                <div class="position-relative bg-light overflow-hidden">
                  <img class="img-fluid w-100" src="img/eggs-and-chicken-1.jpeg" alt="" />
                  <div
                    class="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3"
                  >
                    New
                  </div>
                </div>
                <div class="text-center p-4">
                  <a class="d-block h5 mb-2" href="">Eggs & Chicken</a>
                  <!-- <span class="text-primary me-1">$19.00</span>
                  <span class="text-body text-decoration-line-through"
                    >$29.00</span
                  > -->
                </div>
                <div class="d-flex border-top">
                  <small class="w-50 text-center border-end py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-eye text-primary me-2"></i>View detail</a
                    >
                  </small>
                  <small class="w-50 text-center py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-shopping-bag text-primary me-2"></i>Add
                      to cart</a
                    >
                  </small>
                </div>
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div class="product-item">
                <div class="position-relative bg-light overflow-hidden">
                  <img class="img-fluid w-100" src="img/meat-chicken-breeds.jpeg" alt="" />
                  <div
                    class="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3"
                  >
                    New
                  </div>
                </div>
                <div class="text-center p-4">
                  <a class="d-block h5 mb-2" href="">Meat Chicken Breeds</a>
                  <!-- <span class="text-primary me-1">$19.00</span>
                  <span class="text-body text-decoration-line-through"
                    >$29.00</span
                  > -->
                </div>
                <div class="d-flex border-top">
                  <small class="w-50 text-center border-end py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-eye text-primary me-2"></i>View detail</a
                    >
                  </small>
                  <small class="w-50 text-center py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-shopping-bag text-primary me-2"></i>Add
                      to cart</a
                    >
                  </small>
                </div>
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div class="product-item">
                <div class="position-relative bg-light overflow-hidden">
                  <img class="img-fluid w-100" src="img/001_boerderij-image-1442279.jpeg" alt="" />
                  <div
                    class="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3"
                  >
                    New
                  </div>
                </div>
                <div class="text-center p-4">
                  <a class="d-block h5 mb-2" href="">Fresh Eggs and Meat</a>
                  <!-- <span class="text-primary me-1">$19.00</span>
                  <span class="text-body text-decoration-line-through"
                    >$29.00</span
                  > -->
                </div>
                <div class="d-flex border-top">
                  <small class="w-50 text-center border-end py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-eye text-primary me-2"></i>View detail</a
                    >
                  </small>
                  <small class="w-50 text-center py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-shopping-bag text-primary me-2"></i>Add
                      to cart</a
                    >
                  </small>
                </div>
              </div>
            </div>
            <div class="col-12 text-center wow fadeInUp" data-wow-delay="0.1s">
              <a class="btn btn-primary rounded-pill py-3 px-5" href=""
                >Browse More Products</a
              >
            </div>
          </div>
        </div>
        <div id="tab-3" class="tab-pane fade show p-0">
          <div class="row g-4">
            <div
              class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div class="product-item">
                <div class="position-relative bg-light overflow-hidden">
                  <img class="img-fluid w-100" src="img/Whole-Broiler-Chicken-1.jpeg" alt="" />
                  <div
                    class="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3"
                  >
                    New
                  </div>
                </div>
                <div class="text-center p-4">
                  <a class="d-block h5 mb-2" href="">Chicken Meat</a>
                  <span class="text-primary me-1">RWF 10,000</span>
                  <span class="text-body text-decoration-line-through"
                    >RWF 12,000</span
                  >
                </div>
                <div class="d-flex border-top">
                  <small class="w-50 text-center border-end py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-eye text-primary me-2"></i>View detail</a
                    >
                  </small>
                  <small class="w-50 text-center py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-shopping-bag text-primary me-2"></i>Add
                      to cart</a
                    >
                  </small>
                </div>
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div class="product-item">
                <div class="position-relative bg-light overflow-hidden">
                  <img class="img-fluid w-100" src="img/eggs_.jpeg" alt="" />
                  <div
                    class="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3"
                  >
                    New
                  </div>
                </div>
                <div class="text-center p-4">
                  <a class="d-block h5 mb-2" href="">Chicken Eggs</a>
                  <span class="text-primary me-1">RWF 1000</span>
                  <span class="text-body text-decoration-line-through"
                    >RWF 1500</span
                  >
                </div>
                <div class="d-flex border-top">
                  <small class="w-50 text-center border-end py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-eye text-primary me-2"></i>View detail</a
                    >
                  </small>
                  <small class="w-50 text-center py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-shopping-bag text-primary me-2"></i>Add
                      to cart</a
                    >
                  </small>
                </div>
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div class="product-item">
                <div class="position-relative bg-light overflow-hidden">
                  <img class="img-fluid w-100" src="img/Raising-Meat-Chickens-beth-HOA-FeaturedFacebook-1.jpeg" alt="" />
                  <div
                    class="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3"
                  >
                    New
                  </div>
                </div>
                <div class="text-center p-4">
                  <a class="d-block h5 mb-2" href="">Raising Meat Chicken</a>
                  <span class="text-primary me-1">RWF 5000</span>
                  <span class="text-body text-decoration-line-through"
                    >RWF 7000</span
                  >
                </div>
                <div class="d-flex border-top">
                  <small class="w-50 text-center border-end py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-eye text-primary me-2"></i>View detail</a
                    >
                  </small>
                  <small class="w-50 text-center py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-shopping-bag text-primary me-2"></i>Add
                      to cart</a
                    >
                  </small>
                </div>
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div class="product-item">
                <div class="position-relative bg-light overflow-hidden">
                  <img class="img-fluid w-100" src="img/poultryeggs.jpeg" alt="" />
                  <div
                    class="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3"
                  >
                    New
                  </div>
                </div>
                <div class="text-center p-4">
                  <a class="d-block h5 mb-2" href="">Poultry Eggs</a>
                  <span class="text-primary me-1">RWF 200</span>
                  <span class="text-body text-decoration-line-through"
                    >RWF 300</span
                  >
                </div>
                <div class="d-flex border-top">
                  <small class="w-50 text-center border-end py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-eye text-primary me-2"></i>View detail</a
                    >
                  </small>
                  <small class="w-50 text-center py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-shopping-bag text-primary me-2"></i>Add
                      to cart</a
                    >
                  </small>
                </div>
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div class="product-item">
                <div class="position-relative bg-light overflow-hidden">
                  <img class="img-fluid w-100" src="img/eggs.webp" alt="" />
                  <div
                    class="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3"
                  >
                    New
                  </div>
                </div>
                <div class="text-center p-4">
                  <a class="d-block h5 mb-2" href="">Fresh Eggs</a>
                  <!-- <span class="text-primary me-1">$19.00</span>
                  <span class="text-body text-decoration-line-through"
                    >$29.00</span
                  > -->
                </div>
                <div class="d-flex border-top">
                  <small class="w-50 text-center border-end py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-eye text-primary me-2"></i>View detail</a
                    >
                  </small>
                  <small class="w-50 text-center py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-shopping-bag text-primary me-2"></i>Add
                      to cart</a
                    >
                  </small>
                </div>
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div class="product-item">
                <div class="position-relative bg-light overflow-hidden">
                  <img class="img-fluid w-100" src="img/eggs-and-chicken-1.jpeg" alt="" />
                  <div
                    class="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3"
                  >
                    New
                  </div>
                </div>
                <div class="text-center p-4">
                  <a class="d-block h5 mb-2" href="">Eggs & Chicken</a>
                  <!-- <span class="text-primary me-1">$19.00</span>
                  <span class="text-body text-decoration-line-through"
                    >$29.00</span
                  > -->
                </div>
                <div class="d-flex border-top">
                  <small class="w-50 text-center border-end py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-eye text-primary me-2"></i>View detail</a
                    >
                  </small>
                  <small class="w-50 text-center py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-shopping-bag text-primary me-2"></i>Add
                      to cart</a
                    >
                  </small>
                </div>
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div class="product-item">
                <div class="position-relative bg-light overflow-hidden">
                  <img class="img-fluid w-100" src="img/meat-chicken-breeds.jpeg" alt="" />
                  <div
                    class="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3"
                  >
                    New
                  </div>
                </div>
                <div class="text-center p-4">
                  <a class="d-block h5 mb-2" href="">Meat Chicken Breeds</a>
                  <!-- <span class="text-primary me-1">$19.00</span>
                  <span class="text-body text-decoration-line-through"
                    >$29.00</span
                  > -->
                </div>
                <div class="d-flex border-top">
                  <small class="w-50 text-center border-end py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-eye text-primary me-2"></i>View detail</a
                    >
                  </small>
                  <small class="w-50 text-center py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-shopping-bag text-primary me-2"></i>Add
                      to cart</a
                    >
                  </small>
                </div>
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div class="product-item">
                <div class="position-relative bg-light overflow-hidden">
                  <img class="img-fluid w-100" src="img/001_boerderij-image-1442279.jpeg" alt="" />
                  <div
                    class="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3"
                  >
                    New
                  </div>
                </div>
                <div class="text-center p-4">
                  <a class="d-block h5 mb-2" href="">Fresh Eggs and Meat</a>
                  <!-- <span class="text-primary me-1">$19.00</span>
                  <span class="text-body text-decoration-line-through"
                    >$29.00</span
                  > -->
                </div>
                <div class="d-flex border-top">
                  <small class="w-50 text-center border-end py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-eye text-primary me-2"></i>View detail</a
                    >
                  </small>
                  <small class="w-50 text-center py-2">
                    <a class="text-body" href=""
                      ><i class="fa fa-shopping-bag text-primary me-2"></i>Add
                      to cart</a
                    >
                  </small>
                </div>
              </div>
            </div>
            <div class="col-12 text-center wow fadeInUp" data-wow-delay="0.1s">
              <a class="btn btn-primary rounded-pill py-3 px-5" href=""
                >Browse More Products</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Product End -->

  <!-- Firm Visit Start -->
  <div class="container-fluid bg-primary bg-icon mt-5 py-6">
    <div class="container">
      <div class="row g-5 align-items-center">
        <div class="col-md-7 wow fadeIn" data-wow-delay="0.1s">
          <h1 class="display-5 text-white mb-3">Visit Our Firm</h1>
          <p class="text-white mb-0">
            Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu
            diam amet diam et eos. Clita erat ipsum et lorem et sit, sed stet
            lorem sit clita duo justo magna dolore erat amet. Diam dolor diam
            ipsum sit. Aliqu diam amet diam et eos.
          </p>
        </div>
        <div class="col-md-5 text-md-end wow fadeIn" data-wow-delay="0.5s">
          <a class="btn btn-lg btn-secondary rounded-pill py-3 px-5" href=""
            >Visit Now</a
          >
        </div>
      </div>
    </div>
  </div>
  <!-- Firm Visit End -->

  <!-- Testimonial Start -->
  <div class="container-fluid bg-light bg-icon py-6 mb-5">
    <div class="container">
      <div
        class="section-header text-center mx-auto mb-5 wow fadeInUp"
        data-wow-delay="0.1s"
        style="max-width: 500px"
      >
        <h1 class="display-5 mb-3">Customer Review</h1>
        <p>
          Tempor ut dolore lorem kasd vero ipsum sit eirmod sit. Ipsum diam
          justo sed rebum vero dolor duo.
        </p>
      </div>
      <div
        class="owl-carousel testimonial-carousel wow fadeInUp"
        data-wow-delay="0.1s"
      >
        <div class="testimonial-item position-relative bg-white p-5 mt-4">
          <i
            class="fa fa-quote-left fa-3x text-primary position-absolute top-0 start-0 mt-n4 ms-5"
          ></i>
          <p class="mb-4">
            Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
            amet diam et eos. Clita erat ipsum et lorem et sit.
          </p>
          <div class="d-flex align-items-center">
            <img
              class="flex-shrink-0 rounded-circle"
              src="img/testimonial-1.jpg"
              alt=""
            />
            <div class="ms-3">
              <h5 class="mb-1">Client Name</h5>
              <span>Profession</span>
            </div>
          </div>
        </div>
        <div class="testimonial-item position-relative bg-white p-5 mt-4">
          <i
            class="fa fa-quote-left fa-3x text-primary position-absolute top-0 start-0 mt-n4 ms-5"
          ></i>
          <p class="mb-4">
            Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
            amet diam et eos. Clita erat ipsum et lorem et sit.
          </p>
          <div class="d-flex align-items-center">
            <img
              class="flex-shrink-0 rounded-circle"
              src="img/testimonial-2.jpg"
              alt=""
            />
            <div class="ms-3">
              <h5 class="mb-1">Client Name</h5>
              <span>Profession</span>
            </div>
          </div>
        </div>
        <div class="testimonial-item position-relative bg-white p-5 mt-4">
          <i
            class="fa fa-quote-left fa-3x text-primary position-absolute top-0 start-0 mt-n4 ms-5"
          ></i>
          <p class="mb-4">
            Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
            amet diam et eos. Clita erat ipsum et lorem et sit.
          </p>
          <div class="d-flex align-items-center">
            <img
              class="flex-shrink-0 rounded-circle"
              src="img/testimonial-3.jpg"
              alt=""
            />
            <div class="ms-3">
              <h5 class="mb-1">Client Name</h5>
              <span>Profession</span>
            </div>
          </div>
        </div>
        <div class="testimonial-item position-relative bg-white p-5 mt-4">
          <i
            class="fa fa-quote-left fa-3x text-primary position-absolute top-0 start-0 mt-n4 ms-5"
          ></i>
          <p class="mb-4">
            Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
            amet diam et eos. Clita erat ipsum et lorem et sit.
          </p>
          <div class="d-flex align-items-center">
            <img
              class="flex-shrink-0 rounded-circle"
              src="img/testimonial-4.jpg"
              alt=""
            />
            <div class="ms-3">
              <h5 class="mb-1">Client Name</h5>
              <span>Profession</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Testimonial End -->

  <!-- Footer Start -->
  <FooterItem />
  <!-- Footer End -->

  <!-- Back to Top -->
  <a
    href="#"
    class="btn btn-lg btn-primary btn-lg-square rounded-circle back-to-top"
    ><i class="bi bi-arrow-up"></i
  ></a>
</template>
<script>
import HeaderItem from '../components/HeaderItem.vue'
import FooterItem from '../components/FooterItem.vue'
export default {
  components: {
    HeaderItem,
    FooterItem
  }
}
</script>
