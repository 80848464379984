<template>
  <div
    class="container-fluid bg-dark footer mt-5 pt-5 wow fadeIn"
    data-wow-delay="0.1s"
  >
    <div class="container py-5">
      <div class="row g-5">
        <div class="col-lg-3 col-md-6">
          <h2 class="fw-bold text-primary mb-4">
            Lil's <span class="text-secondary">Chicks </span>Farm
          </h2>
          <p>
            Welcome to Lili's Chicks, your trusted source for premium poultry
            products in Rwanda.
          </p>
          <div class="d-flex pt-2">
            <a
              class="btn btn-square btn-outline-light rounded-circle me-1"
              href=""
              ><i class="fab fa-twitter"></i
            ></a>
            <a
              class="btn btn-square btn-outline-light rounded-circle me-1"
              href=""
              ><i class="fab fa-facebook-f"></i
            ></a>
            <a
              class="btn btn-square btn-outline-light rounded-circle me-1"
              href=""
              ><i class="fab fa-youtube"></i
            ></a>
            <a
              class="btn btn-square btn-outline-light rounded-circle me-0"
              href=""
              ><i class="fab fa-linkedin-in"></i
            ></a>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <h4 class="text-light mb-4">Address</h4>
          <p>
            <i class="fa fa-map-marker-alt me-3"></i>123 Street, kicukiro,
            Kigali, RWANDA
          </p>
          <p><i class="fa fa-phone-alt me-3"></i>0789326245</p>
          <p>
            <i class="fa fa-envelope me-3"></i>ganzatambaheritier24@example.com
          </p>
        </div>
        <div class="col-lg-3 col-md-6">
          <h4 class="text-light mb-4">Quick Links</h4>
          <a class="btn btn-link" href="">About Us</a>
          <a class="btn btn-link" href="">Contact Us</a>
          <a class="btn btn-link" href="">Our Services</a>
          <a class="btn btn-link" href="">Terms & Condition</a>
          <a class="btn btn-link" href="">Support</a>
        </div>
        <div class="col-lg-3 col-md-6">
          <h4 class="text-light mb-4">Newsletter</h4>
          <p>Dolor amet sit justo amet elitr clita ipsum elitr est.</p>
          <div class="position-relative mx-auto" style="max-width: 400px">
            <input
              class="form-control bg-transparent w-100 py-3 ps-4 pe-5"
              type="text"
              placeholder="Your email"
            />
            <button
              type="button"
              class="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2"
            >
              SignUp
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid copyright">
      <div class="container">
        <div class="row">
          <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">
            &copy; <a href="#">Lil's Chicks Farm</a>, All Right Reserved.
          </div>
          <div class="col-md-6 text-center text-md-end">
            <!--/*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. ***/-->
            Designed By
            <a href="https://portfolio-ganza.pages.dev/">Heritier Ganza</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
